import React, { FunctionComponent, useState, useEffect } from "react";
import {
    Button,
    Checkbox,
    Heading,
    HStack,
    Text,
    Image,
    VStack,
    Center,
    Stack,
    Link,
} from "@chakra-ui/react";
import googleIcon from "../assets/google-icon.png";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { InputForm } from "../components/common/InputForm";
import { navigate, RouteComponentProps, useLocation } from "@reach/router";

import { hashPassword } from "../../lib/util";
import { useApp } from "../../lib/app/App";
import { useQueryClient } from "@tanstack/react-query";
import { AdminFillFormButton } from "../../lib/app/AdminFillFormButton";
import { EnterCodeComponent } from "../components/register/EnterCode";
import { Dot } from "../components/common/Dot";
import { Footer } from "../components/common/Footer";
import { Header } from "../components/common/Header";
import { getCookie } from "../../lib/util";
import { ProgressButton } from "../components/common/ProgressButton";

const EmailStep = ({ email, onContinueHandler, feedback, setFeedback }) => {
    const schema = z.object({
        Email: z.string().email(),
    });

    const {
        register,
        formState: { errors },
        watch,
        getValues,
        setValue,
        handleSubmit,
    } = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            Email: email || "",
        },
    });

    const emailValue = watch("Email");

    function onAutofill() {
        const isPreview = window.localStorage.getItem(
            "re-unlock-preview-token",
        );
        if (isPreview) {
            const lastEmail = window.localStorage.getItem(
                "re-preview-register-last-email",
            );
            if (!getValues("Email") && lastEmail && lastEmail !== "null") {
                setValue("Email", lastEmail);
            }
        }
    }

    useEffect(() => {
        if (emailValue) {
            setFeedback(null);
        }
    }, [emailValue]);

    const onContinue = ({ Email }) => {
        onContinueHandler({ Email });
    };

    return (
        <Stack
            direction={{ base: "column", lg: "row" }}
            zIndex={"2"}
            spacing={{ base: "44px", lg: "144px" }}
            bg={"#fff"}
            borderRadius={"20px"}
        >
            <VStack
                bg={"rgba(19, 106, 255, 0.2)"}
                maxW={"415px"}
                w={{ base: "auto", sm: "415px" }}
                p={"30px"}
                boxShadow={"0px 4px 14px rgba(19, 106, 255, 0.2)"}
                borderRadius={"20px"}
                spacing={"11px"}
            >
                <Heading
                    textAlign={"center"}
                    w={"100%"}
                    fontSize={"26px"}
                    size={"md"}
                    lineHeight={"20px"}
                    mb="15px"
                >
                    Welcome
                </Heading>

                <Text alignSelf={"center"}>
                    Enter your email address below.
                </Text>
                <AdminFillFormButton onClick={onAutofill} />
                <VStack
                    w={{ base: "100%", sm: "auto" }}
                    alignItems={"flex-start"}
                >
                    <Text fontSize={"12px"}>Email address</Text>
                    <InputForm
                        placeholder={"Enter email address"}
                        name={"Email"}
                        required={true}
                        register={register}
                        height={"40px"}
                        width={{ base: "100%", sm: "354px" }}
                        type={"email"}
                        rest={{
                            fontSize: "14px",
                            border: "1px solid #ACCBFF",
                            borderRadius: "37px",
                            bg: "#ffffff",
                        }}
                        error={errors?.Email?.message as string | undefined}
                    />
                </VStack>

                {feedback?.error?.code === "USER_NOT_FOUND" && (
                    <Text fontWeight="bold" fontSize={"14px"} color="#FF0000">
                        A user with that email address does not exist!
                    </Text>
                )}

                <Button
                    bg={"#136AFF"}
                    borderRadius={"30px"}
                    width={"171px"}
                    minHeight={"40px"}
                    color={"#FFFFFF"}
                    fontWeight={"900"}
                    fontSize={"16px"}
                    lineHeight={"20px"}
                    onClick={handleSubmit(onContinue)}
                >
                    Continue to Sign in
                </Button>

                <Text alignSelf={"center"}>
                    If you would like to register please{" "}
                    <Link
                        textDecoration={"underline"}
                        href="https://rellie.com/contact-us/"
                    >
                        contact us
                    </Link>
                </Text>
            </VStack>
        </Stack>
    );
};

const PasswordStep = ({
    email,
    onContinueHandler,
    feedback,
    setFeedback,
    onLoginWithDifferentEmail,
    onResetPassword,
}) => {
    const schema = z.object({
        Password: z
            .string()
            .min(1, "Password is required")
            .min(8, "Password must have more than 8 characters"),
        isPublicDevice: z.boolean(),
    });

    const {
        register,
        formState: { errors },
        watch,
        getValues,
        setValue,
        handleSubmit,
    } = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            isPublicDevice:
                window.localStorage.getItem("re-is-public-device") === "1",
        },
    });

    const passwordValue = watch("Password");

    function onAutofill() {
        const isPreview = window.localStorage.getItem(
            "re-unlock-preview-token",
        );
        if (isPreview) {
            const lastPassword = window.localStorage.getItem(
                "re-preview-register-last-password",
            );
            if (!getValues("Password") && lastPassword) {
                setValue("Password", lastPassword);
            }
        }
    }

    useEffect(() => {
        if (passwordValue) {
            setFeedback(null);
        }
    }, [passwordValue]);

    const onSignIn = ({ Password, isPublicDevice }) => {
        onContinueHandler({ Password, isPublicDevice });
    };

    const isPublicDevice = watch("isPublicDevice");
    useEffect(() => {
        if (isPublicDevice) {
            window.localStorage.setItem("re-is-public-device", "1");
        } else {
            window.localStorage.removeItem("re-is-public-device");
        }
    }, [isPublicDevice]);

    return (
        <Stack
            direction={{ base: "column", lg: "row" }}
            zIndex={"2"}
            spacing={{ base: "44px", lg: "144px" }}
            bg={"#fff"}
            borderRadius={"20px"}
        >
            <VStack
                bg={"rgba(19, 106, 255, 0.2)"}
                maxW={"415px"}
                w={{ base: "auto", sm: "415px" }}
                p={"30px"}
                boxShadow={"0px 4px 14px rgba(19, 106, 255, 0.2)"}
                borderRadius={"20px"}
                spacing={"11px"}
            >
                <Heading
                    textAlign={"center"}
                    w={"100%"}
                    fontSize={"26px"}
                    size={"md"}
                    lineHeight={"20px"}
                    mb="15px"
                >
                    Welcome
                </Heading>

                <Text alignSelf={"center"}>Enter your password below.</Text>
                <Text alignSelf={"center"}>
                    To login with a different email address,{" "}
                    <a
                        onClick={onLoginWithDifferentEmail}
                        style={{ color: "#0000FF" }}
                    >
                        click here
                    </a>
                    .
                </Text>
                <AdminFillFormButton onClick={onAutofill} />
                {/* <Text
                    alignSelf={"flex-start"}
                    fontSize={"16px"}
                    color={"#032E59"}
                    fontWeight={"700"}
                >
                    Account Information
                </Text> */}
                <VStack
                    w={{ base: "100%", sm: "auto" }}
                    alignItems={"flex-start"}
                >
                    <Text fontSize={"12px"}>Email address</Text>
                    <InputForm
                        placeholder={"Enter email address"}
                        name={"Email"}
                        required={true}
                        height={"40px"}
                        width={{ base: "100%", sm: "354px" }}
                        type={"email"}
                        rest={{
                            disabled: true,
                            defaultValue: email,
                            fontSize: "14px",
                            border: "1px solid #ACCBFF",
                            borderRadius: "37px",
                            bg: "#ffffff",
                        }}
                    />
                </VStack>
                <VStack
                    w={{ base: "100%", sm: "auto" }}
                    alignItems={"flex-start"}
                >
                    <Text fontSize={"12px"}>Password</Text>
                    <InputForm
                        placeholder={"Enter password"}
                        name={"Password"}
                        required={true}
                        register={register}
                        height={"40px"}
                        width={{ base: "100%", sm: "354px" }}
                        type={"password"}
                        rest={{
                            fontSize: "14px",
                            border: "1px solid #ACCBFF",
                            borderRadius: "37px",
                            bg: "#ffffff",
                        }}
                        error={errors?.Password?.message as string | undefined}
                    />
                </VStack>

                {feedback?.error?.code === "USER_NOT_FOUND" && (
                    <Text fontWeight="bold" fontSize={"14px"} color="#FF0000">
                        A user with that email address does not exist!
                    </Text>
                )}

                {feedback?.error?.code === "PASSWORD_INCORRECT" && (
                    <Text fontSize={"14px"} color="#FF0000" fontWeight={"bold"}>
                        Your password is not correct! Please try again.
                    </Text>
                )}

                {feedback?.error?.code ===
                    "USER_MUST_USE_GOOGLE_OUTH_TO_LOGIN" && (
                    <Text fontSize={"14px"} color="#FF0000" fontWeight={"bold"}>
                        You must login using the Google Login option.
                    </Text>
                )}

                <Button
                    bg={"#136AFF"}
                    borderRadius={"30px"}
                    width={"171px"}
                    minHeight={"40px"}
                    color={"#FFFFFF"}
                    fontWeight={"900"}
                    fontSize={"16px"}
                    lineHeight={"20px"}
                    onClick={handleSubmit(onSignIn)}
                >
                    Sign in
                </Button>
                <HStack
                    color="#032E59"
                    justifyContent={"space-between"}
                    w={"100%"}
                    fontWeight={700}
                    mt="18px"
                >
                    <Checkbox
                        size="sm"
                        colorScheme="brand.blue"
                        border={"#136AFF"}
                        {...register("isPublicDevice")}
                    >
                        <Text fontSize={"12px"} color={"#032E59"}>
                            I am on a public device.
                        </Text>
                    </Checkbox>
                    {/* <Link>Create Account</Link> */}
                    {/* <ResetPassword /> */}
                </HStack>

                <Text>
                    <Link
                        fontWeight={"700"}
                        color={"#032E59"}
                        onClick={onResetPassword}
                    >
                        Reset Password
                    </Link>
                </Text>
            </VStack>
        </Stack>
    );
};

const GoogleStep = ({ email, onLoginWithDifferentEmail }) => {
    const { formatRequestUrl } = useApp();

    const [isPublicDevice, setIsPublicDevice] = useState<boolean>(
        window.localStorage.getItem("re-is-public-device") === "1",
    );

    useEffect(() => {
        if (isPublicDevice) {
            window.localStorage.setItem("re-is-public-device", "1");
        } else {
            window.localStorage.removeItem("re-is-public-device");
        }
    }, [isPublicDevice]);

    function triggerLogin() {
        window.location.href = formatRequestUrl("login-google");
    }

    return (
        <Stack
            direction={{ base: "column", lg: "row" }}
            zIndex={"2"}
            spacing={{ base: "44px", lg: "144px" }}
            bg={"#fff"}
            borderRadius={"20px"}
        >
            <VStack
                bg={"rgba(19, 106, 255, 0.2)"}
                maxW={"415px"}
                w={{ base: "auto", sm: "415px" }}
                p={"30px"}
                boxShadow={"0px 4px 14px rgba(19, 106, 255, 0.2)"}
                borderRadius={"20px"}
                spacing={"11px"}
            >
                <Heading
                    textAlign={"center"}
                    w={"100%"}
                    fontSize={"26px"}
                    size={"md"}
                    lineHeight={"20px"}
                    mb="15px"
                >
                    Welcome
                </Heading>

                <Text alignSelf={"center"}>
                    You must login with your <b>{email}</b> Google account.
                </Text>

                <Button
                    w={"233px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    bg={"#ffffff"}
                    border={"1px solid #136AFF"}
                    fontSize={{ base: "14px", lg: "16px" }}
                    onClick={triggerLogin}
                >
                    <Image src={googleIcon} maxW={"30px"} maxH={"30px"} />
                    Sign-in with Google
                </Button>
                <VStack w={"100%"} alignItems={"start"}>
                    <Checkbox
                        size="sm"
                        colorScheme="brand.blue"
                        border={"#136AFF"}
                        isChecked={!!isPublicDevice}
                        onChange={(e) => setIsPublicDevice(e.target.checked)}
                    >
                        <Text fontSize={"12px"} color={"#032E59"}>
                            I am on a public device.
                        </Text>
                    </Checkbox>
                </VStack>

                <Text alignSelf={"center"}>
                    To login with a different email address,{" "}
                    <a
                        onClick={onLoginWithDifferentEmail}
                        style={{ color: "#0000FF" }}
                    >
                        click here
                    </a>
                    .
                </Text>
            </VStack>
        </Stack>
    );
};

const ResetPasswordStep = ({
    email,
    onSendResetEmailHandler,
    feedback,
    setFeedback,
    onBackToLoginHandler,
}) => {
    return (
        <Stack
            direction={{ base: "column", lg: "row" }}
            zIndex={"2"}
            spacing={{ base: "44px", lg: "144px" }}
            bg={"#fff"}
            borderRadius={"20px"}
        >
            <VStack
                bg={"rgba(19, 106, 255, 0.2)"}
                maxW={"415px"}
                w={{ base: "auto", sm: "415px" }}
                p={"30px"}
                boxShadow={"0px 4px 14px rgba(19, 106, 255, 0.2)"}
                borderRadius={"20px"}
                spacing={"11px"}
            >
                <Heading
                    textAlign={"center"}
                    w={"100%"}
                    fontSize={"26px"}
                    size={"md"}
                    lineHeight={"20px"}
                    mb="15px"
                >
                    Reset Password
                </Heading>

                <Text alignSelf={"center"}>
                    We will send an email to the account below. Follow the
                    instructions to reset your password.
                </Text>
                <VStack
                    w={{ base: "100%", sm: "auto" }}
                    alignItems={"flex-start"}
                >
                    <Text fontSize={"12px"}>Email address</Text>
                    <InputForm
                        placeholder={"Enter email address"}
                        name={"Email"}
                        required={true}
                        height={"40px"}
                        width={{ base: "100%", sm: "354px" }}
                        type={"email"}
                        rest={{
                            disabled: true,
                            defaultValue: email,
                            fontSize: "14px",
                            border: "1px solid #ACCBFF",
                            borderRadius: "37px",
                            bg: "#ffffff",
                        }}
                    />
                </VStack>

                {feedback?.success?.code === "RESET_EMAIL_SENT" && (
                    <Text
                        fontSize={"14px"}
                        color={"#032E59"}
                        fontWeight={"bold"}
                    >
                        A reset email has been sent! Check your email and follow
                        the instructions.
                    </Text>
                )}

                {feedback?.error?.code === "USER_NOT_FOUND" && (
                    <Text fontWeight="bold" fontSize={"14px"} color="#FF0000">
                        A user with that email address does not exist!
                    </Text>
                )}

                {!feedback && (
                    <ProgressButton
                        getTag={({ isDisabled, onClick }) => (
                            <Button
                                isDisabled={isDisabled}
                                bg={"#136AFF"}
                                borderRadius={"30px"}
                                width={"171px"}
                                minHeight={"40px"}
                                color={"#FFFFFF"}
                                fontWeight={"900"}
                                fontSize={"16px"}
                                lineHeight={"20px"}
                                onClick={onClick}
                            >
                                Send Reset Email
                            </Button>
                        )}
                        onClick={async ({ resetButton }) => {
                            await onSendResetEmailHandler();
                            resetButton();
                        }}
                    />
                )}

                <Text alignSelf={"center"}>
                    Back to{" "}
                    <a
                        onClick={onBackToLoginHandler}
                        style={{ color: "#0000FF" }}
                    >
                        login
                    </a>
                    .
                </Text>
            </VStack>
        </Stack>
    );
};

export const Login: FunctionComponent<RouteComponentProps> = () => {
    const { api, useData, webappOrigin, formatRequestUrl } = useApp();

    const qs = new URLSearchParams(useLocation().search);

    const queryClient = useQueryClient();
    const userData = useData("User");

    const [currentStep, setCurrentStep] = useState(0);

    const [email, setEmail] = useState(null);

    if (email === null && qs.get("email") && qs.get("email") !== email) {
        setEmail(qs.get("email"));
    } else if (email === null && getCookie("re-user-last-login-email")) {
        setEmail(getCookie("re-user-last-login-email"));
    }

    useEffect(() => {
        // NOTE: We call this just in case to make testing and complex user flows reliable.
        api.User.DeleteRegistrationToken.mutate().catch((err) => {
            console.error("Error calling User.DeleteRegistrationToken", err);
        });
    }, [true]);

    const [feedback, setFeedback] = useState(null);
    const [codeFeedback, setCodeFeedback] = useState(null);

    useEffect(() => {
        setFeedback(null);
    }, [currentStep]);

    const onLoginWithDifferentEmail = () => {
        setEmail("");
        setCurrentStep(0);
    };

    const onResetPassword = () => {
        setCurrentStep(3);
    };

    const onBackToLoginHandler = () => {
        setCurrentStep(0);
    };

    const onContinueHandler = async function (args) {
        if (currentStep === 0) {
            // Capture Email

            const { Email } = args;

            setEmail(Email);

            const response = await api.User.FindLoginMode.mutate({
                Email,
            });

            if (response.error) {

                if (!response.error.code && response.error.message === "Failed to fetch") {
                    navigate(`/error?code=ERROR_LOADING_APPLICATION_BACKEND_API`);
                    return
                }

                if (!["USER_NOT_FOUND"].includes(response.error.code)) {
                    console.error('response.error', response)
                    throw new Error(
                        `Unknown error code '${response.error.code}'!`,
                    );
                }
                setFeedback(response);
                return;
            } else if (response.LoginMode) {
                if (response.LoginMode === "PASSWORD") {
                    setCurrentStep(1);
                } else if (response.LoginMode === "GOOGLE_OAUTH") {
                    setCurrentStep(2);
                } else {
                    throw new Error(
                        `Unknown login mode '${response.LoginMode}'!`,
                    );
                }
            } else if (response.success) {
                // NOTE: Used in development only to login users immediately.
                window.location.reload()
            } else {
                console.error("response", response);
                throw new Error(`Unknown response format!`);
            }
        } else if (currentStep === 1) {
            // Password Login

            const Password = hashPassword(args.Password);
            //TODO: add isPublicDevice to the mutation
            const isPublicDevice = args.isPublicDevice;

            const response = await api.User.LoginPassword.mutate({
                Email: email,
                Password,
            });

            if (response.error) {
                if (
                    ![
                        "USER_NOT_FOUND",
                        "PASSWORD_INCORRECT",
                        "USER_MUST_USE_GOOGLE_OUTH_TO_LOGIN",
                    ].includes(response.error.code)
                ) {
                    throw new Error(
                        `Unknown error code '${response.error.code}'!`,
                    );
                }
                setFeedback(response);
                return;
            } else if (response.success?.login) {
                queryClient.invalidateQueries({ queryKey: ["User"] });
                // setCurrentStep(1);
                return;
            }
        } else if (currentStep === 3) {
            // Reset Password

            const response = await api.User.SendResetPasswordEmail.mutate({
                Email: email,
                webappOrigin,
                registrationBaseUrl: formatRequestUrl(
                    "api",
                    "/api/user/register/from-email",
                ),
            });

            if (response.error) {
                if (!["USER_NOT_FOUND"].includes(response.error.code)) {
                    throw new Error(
                        `Unknown error code '${response.error.code}'!`,
                    );
                }
                setFeedback(response);
                return;
            } else if (response.success) {
                setFeedback({
                    success: {
                        code: "RESET_EMAIL_SENT",
                    },
                });
                setTimeout(() => {
                    setFeedback(null);
                }, 5 * 1000);
            }
        }
    };

    if (userData.isFetched && userData.data.login?.role) {
        navigate(`/${userData.data.login?.role}/dashboard`);
        return null;
    }

    const renderContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <EmailStep
                        feedback={feedback}
                        setFeedback={setFeedback}
                        email={email}
                        onContinueHandler={onContinueHandler}
                    />
                );
            case 1:
                return (
                    <PasswordStep
                        onLoginWithDifferentEmail={onLoginWithDifferentEmail}
                        onResetPassword={onResetPassword}
                        feedback={feedback}
                        setFeedback={setFeedback}
                        email={email}
                        onContinueHandler={onContinueHandler}
                    />
                );
            case 2:
                return (
                    <GoogleStep
                        email={email}
                        onLoginWithDifferentEmail={onLoginWithDifferentEmail}
                    />
                );
            case 3:
                return (
                    <ResetPasswordStep
                        onSendResetEmailHandler={onContinueHandler}
                        feedback={feedback}
                        setFeedback={setFeedback}
                        email={email}
                        onBackToLoginHandler={onBackToLoginHandler}
                    />
                );
            case 4:
                return (
                    <EnterCodeComponent
                        feedback={codeFeedback}
                        setCodeFeedback={setCodeFeedback}
                        onResendCodeHandler={async () => {
                            setCodeFeedback(null);
                            // await api.User.SendLoginCodeEmail.mutate({
                            //     token: qs.get("token"),
                            // });
                            // setCodeFeedback({
                            //     success: {
                            //         code: "VERIFICATION_CODE_RESENT",
                            //     },
                            // });
                        }}
                        onContinueHandler={async ({
                            firstDigit,
                            secondDigit,
                            thirdDigit,
                            fourthDigit,
                        }) => {
                            // const isPreview = window.localStorage.getItem(
                            //     "re-unlock-preview-token",
                            // );
                            // if (isPreview) {
                            //     window.localStorage.setItem(
                            //         "re-preview-register-last-email",
                            //         qs.get("email"),
                            //     );
                            //     window.localStorage.setItem(
                            //         "re-preview-register-last-password",
                            //         passwordValue,
                            //     );
                            // }
                            // const hashedPassword = hashPassword(passwordValue);
                            // const response = await api.User.Login.mutate({
                            //     password: hashedPassword,
                            //     token: qs.get("token"),
                            //     verificationCode: `${firstDigit}${secondDigit}${thirdDigit}${fourthDigit}`,
                            // });
                            // if (response.error) {
                            //     if (
                            //         ![
                            //             "VERIFICATION_CODE_EXPIRED",
                            //             "VERIFICATION_CODE_MISMATCH",
                            //             "ACCOUNT_WITH_EMAIL_ALREADY_EXISTS",
                            //         ].includes(response.error.code)
                            //     ) {
                            //         throw new Error(
                            //             `Unknown error code '${response.error.code}'!`,
                            //         );
                            //     }
                            //     setCodeFeedback(response);
                            //     return;
                            // } else if (response.success) {
                            //     const { role } = response.success.login;
                            //     adminContext.selectRole(role, false);
                            //     navigate(`/${role}/dashboard`);
                            // } else {
                            //     throw new Error(
                            //         `Invalid response: ${JSON.stringify(
                            //             response,
                            //         )}`,
                            //     );
                            // }
                        }}
                    />
                );
        }
    };

    return (
        <>
            <Header />
            <Center mt={"-30px"} h={"100vh"} zIndex={"0"}>
                {renderContent()}
                <Stack zIndex={-1}>
                    <Dot
                        size={"29px"}
                        color={"#1CCF8C"}
                        top={{ base: "2%", lg: "17.375%" }}
                        left={{ base: "82%", lg: "83.17%" }}
                    />
                    <Dot
                        size={"41px"}
                        color={"#F5C306"}
                        top={{ base: "2%", lg: "18.875%" }}
                        left={{ base: "18%", lg: "24.33%" }}
                    />
                    <Dot
                        size={"44px"}
                        color={"#FFFC82"}
                        top={{ base: "41%", lg: "46.84%" }}
                        left={{ base: "3%", lg: "8.68%" }}
                    />
                    <Dot
                        size={"18px"}
                        color={"#136AFF"}
                        top={{ base: "21%", lg: "26.76%" }}
                        left={{ base: "90%", lg: "76.05%" }}
                    />
                    <Dot
                        size={"53px"}
                        color={"#F56806"}
                        top={{ base: "41%", lg: "57.23%" }}
                        left={{ base: "89%", lg: "77.44%" }}
                    />
                    <Dot
                        size={"53px"}
                        color={"#ED47C4"}
                        top={{ base: "16%", lg: "60.28%" }}
                        left={{ base: "3%", lg: "20.83%" }}
                    />
                </Stack>
            </Center>
            <Footer />
        </>
    );
};
