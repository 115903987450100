import React, { FunctionComponent, useState } from "react";
import resend from "../../assets/resend.svg";

import {
    Box,
    Button,
    Flex,
    HStack,
    Icon,
    Image,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { Searchbar } from "../../components/common/Searchbar";
import { BsFillCheckCircleFill, BsPlusCircleFill } from "react-icons/bs";
import { HiOutlinePencil } from "react-icons/hi";
import { RouteComponentProps, navigate } from "@reach/router";
import { AddStudentModal } from "../../components/teacher/AddStudentModal";
import { UploadCsvModal } from "../../components/UploadCsvModal";
import { useApp, isAdminToolsEnabled } from "../../../lib/app/App";
import { EditStudentModal } from "../../components/teacher/EditStudentModal";
import { ProgressButton } from "../../components/common/ProgressButton";
import { isNil, get, set } from "lodash";
import { HiOutlineTrash } from "react-icons/hi2";
import { DeletePopup } from "../../components/common/DeletePopup";
import { ConfirmationPopup } from "../../components/common/ConfirmationPopup";
import { InviteColleagueModal } from "../../components/teacher/InviteColleagueModal";
import { AddClassModal } from "../../components/teacher/AddClassModal";
import { ShareWithColleagueModal } from "../../components/teacher/ShareWithColleagueModal";

export const TeacherLicensesPage: FunctionComponent<
    RouteComponentProps
> = () => {
    const { api, useData, webappOrigin, formatRequestUrl, onImpersonate } =
        useApp();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenCsv,
        onOpen: onOpenCsv,
        onClose: onCloseCsv,
    } = useDisclosure();

    const {
        isOpen: isOpenEditModal,
        onOpen: onOpenEditModal,
        onClose: onCloseEditModal,
    } = useDisclosure();

    const {
        isOpen: isOpenDeleteModal,
        onOpen: onOpenDeleteModal,
        onClose: onCloseDeleteModal,
    } = useDisclosure();

    const {
        isOpen: isOpenConfirmationBulkSendModal,
        onOpen: onOpenConfirmationBulkSendModal,
        onClose: onCloseConfirmationBulkSendModal,
    } = useDisclosure();

    const [modalContext, setModalContext] = useState(null);
    const [bulkSendModalContext, setBulkSendModalContext] = useState(null);

    const licenses = useData("Teacher.Licenses");

    const [editLicense, setEditLicense] = useState(null);

    const showBulkSend = {};
    for (const license of licenses.data?.studentLicenses || []) {
        if (license.registrationStatus === "ADDED") {
            set(showBulkSend, [license.Administrator_Teacher_License.Class], "SEND_ALL");
        }
        if (
            showBulkSend[license.Administrator_Teacher_License.Class] !== "SEND_ALL" &&
            license.registrationStatus === "INVITED"
        ) {
            set(showBulkSend, [license.Administrator_Teacher_License.Class], "RESEND_ALL");
        }
    }

    const pendingInvitesCount = {};
    for (const license of licenses.data?.studentLicenses || []) {
        set(pendingInvitesCount, [license.Administrator_Teacher_License.Class], get(pendingInvitesCount, [license.Administrator_Teacher_License.Class]) || 0);
        if (showBulkSend[license.Administrator_Teacher_License.Class] === "SEND_ALL") {
            if (license.registrationStatus === "ADDED") {
                pendingInvitesCount[license.Administrator_Teacher_License.Class] += 1
            }
        } else {
            if (license.registrationStatus === "INVITED") {
                pendingInvitesCount[license.Administrator_Teacher_License.Class] += 1
            }
        }
    }

    function onConfirmBulkSend({
        Class,
        Id_Administrator_Teacher_License,
    }) {
        setBulkSendModalContext({
            Class,
            Id_Administrator_Teacher_License,
        });
        onOpenConfirmationBulkSendModal();
    }

    async function addStudent(data) {
        await api.Teacher.Licenses.AddStudent.mutate({
            ...data,
            Id_Administrator_Teacher_License:
                modalContext.Id_Administrator_Teacher_License,
            registrationBaseUrl: formatRequestUrl(
                "api",
                "/api/user/register/from-email",
            ),
        });
        licenses.refetch();
        onClose();
    }

    async function addExistingStudent (email) {
        await api.Teacher.Licenses.AddExistingStudent.mutate({
            Id_Administrator_Teacher_License:
                modalContext.Id_Administrator_Teacher_License,
            StudentEmail: email
        });
        licenses.refetch();
        onClose();
    }

    async function updateStudent(Id_Teacher_Student_License, data) {

        await api.Teacher.Licenses.UpdateStudent.mutate({
            Id_Teacher_Student_License,
            fields: data,
        });

        licenses.refetch();

        onCloseEditModal();
    }

    async function onSendInvite(Id_License) {
        const { email } = await api.Teacher.Licenses.SendInvitation.mutate({
            Id_License,
            webappOrigin,
            registrationBaseUrl: formatRequestUrl(
                "api",
                "/api/user/register/from-email",
            ),
        });

        const isPreview = window.localStorage.getItem(
            "re-unlock-preview-token",
        );
        if (isPreview) {
            window.localStorage.setItem("re-preview-invite-last-email", email);
        }

        licenses.refetch();
    }

    async function onSendAllInvites(Id_Administrator_Teacher_License, registrationStatus) {

        await api.Teacher.Licenses.SendAllInvitations.mutate({
            Id_Administrator_Teacher_License,
            registrationStatus,
            webappOrigin,
            registrationBaseUrl: formatRequestUrl(
                "api",
                "/api/user/register/from-email",
            ),
        });

        licenses.refetch();
    }

    async function onResendAssessmentInvite(Id_Student_Assessment) {
        await api.Teacher.Assessment.SendInvitation.mutate({
            Id_Student_Assessment,
            webappOrigin,
        });
        licenses.refetch();
    }

    function onAddStudentOpenDialog({
        Grade,
        Class,
        Id_Administrator_Teacher_License,
    }) {
        return () => {
            setModalContext({ Grade, Class, Id_Administrator_Teacher_License });
            onOpen();
        };
    }

    function onOpenImport({ Grade, Class }) {
        return () => {
            setModalContext({ Grade, Class });
            onOpenCsv();
        };
    }

    // console.log("licenses.data", licenses.data);

    // console.log('licenses.data?.studentLicenses', licenses.data?.studentLicenses);

    return (
        <VStack alignItems={"flex-start"} spacing={"20px"}>
            {isOpen && (
                <AddStudentModal
                    isOpen={isOpen}
                    onClose={onClose}
                    addStudent={addStudent}
                    addExistingStudent={addExistingStudent}
                    modalContext={modalContext}
                />
            )}
            {isOpenEditModal && !isNil(editLicense) && (
                <EditStudentModal
                    isOpen={isOpenEditModal}
                    onClose={onCloseEditModal}
                    license={editLicense}
                    updateStudent={updateStudent}
                />
            )}

            {isOpenDeleteModal && !isNil(editLicense) && (
                <DeletePopup
                    isOpen={isOpenDeleteModal}
                    onClose={onCloseDeleteModal}
                    onDelete={() => {
                        onCloseDeleteModal();
                    }}
                    confirmationText={`Are you sure you want to delete ${editLicense.StudentFirstName}?`}
                />
            )}

            {isOpenCsv && (
                <UploadCsvModal
                    modalContext={modalContext}
                    isOpen={isOpenCsv}
                    onClose={onCloseCsv}
                    onUpload={() => {
                        //todo: upload csv
                        onCloseCsv();
                    }}
                />
            )}

            {isOpenConfirmationBulkSendModal && (
                <ConfirmationPopup
                    isOpen={isOpenConfirmationBulkSendModal}
                    onClose={
                        onCloseConfirmationBulkSendModal
                    }
                    onConfirmation={async () => {
                        await onSendAllInvites(
                            bulkSendModalContext.Id_Administrator_Teacher_License,
                            showBulkSend[bulkSendModalContext.Class] === "SEND_ALL"
                                ? "ADDED"
                                : "INVITED",
                        );
                        onCloseConfirmationBulkSendModal();
                    }}
                    confirmationButtonText={"Send"}
                    confirmationText={`Send registration invite to ${pendingInvitesCount[bulkSendModalContext.Class]} recipients?`}
                />
            )}
            
            <Searchbar />
            <Box alignSelf={"start"} color={"#032E59"} fontWeight={"700"} width="100%">
                <Text fontSize={"26px"}>Teacher</Text>

                <HStack
                    width="100%"
                    justifyContent={"space-between"}
                    paddingRight="15px"
                >
                    <Text fontSize={"16px"}>License Management</Text>

                    <HStack>
                        <InviteColleagueModal/>
                    </HStack>

                </HStack>   
            </Box>

            <AddClassModal onClassAdded={() => {
                licenses.refetch()
            }}/>

            {(licenses.data?.teacherLicenses || []).map((teacherLicense, i) => (
                <VStack
                    key={i}
                    bg={"rgba(19, 106, 255, 0.03)"}
                    borderRadius={"20px"}
                    p={"17px"}
                    w={"100%"}
                    spacing={"18px"}
                >
                    <VStack w="100%">
                        <Text
                            alignSelf={"start"}
                            color={"#032E59"}
                            fontWeight={"700"}
                            fontSize={"16px"}
                        >
                            Class: {teacherLicense.Class} &nbsp;&nbsp;&nbsp; Grade: {teacherLicense.Grade}
                        </Text>
                        <HStack
                            key={i}
                            w={"100%"}
                            justifyContent={"space-between"}
                        >
                            <HStack spacing={"25px"}>
                                {/* <Text fontSize={"14px"}>
                                    Available:{" "}
                                    {teacherLicense.MaxStudents -
                                        teacherLicense.usedStudents}
                                </Text>
                                <Text fontSize={"14px"}>
                                    Total: {teacherLicense.MaxStudents}
                                </Text> */}
                            </HStack>
                            <HStack>
                                {/* <Button
                                        leftIcon={
                                            <AiOutlineDownload
                                                strokeWidth="1px"
                                                size="14px"
                                            />
                                        }
                                        color="#0051DB"
                                        variant="outline"
                                        borderColor="#0051DB"
                                        borderRadius={"41px"}
                                        height={"22px"}
                                        fontSize={"12px"}
                                        onClick={onOpenImport({
                                            Grade: teacherLicense.Grade,
                                            Class: teacherLicense.Class,
                                        })}
                                    >
                                        Import
                                    </Button> */}

                                <ShareWithColleagueModal
                                    Id_Administrator_Teacher_License={teacherLicense.Id}
                                />

                                {!isNil(showBulkSend[teacherLicense.Class]) && (
                                    <ProgressButton
                                        style={
                                            showBulkSend[teacherLicense.Class] === "SEND_ALL"
                                                ? "primary"
                                                : "secondary"
                                        }
                                        label={
                                            showBulkSend[teacherLicense.Class] === "SEND_ALL"
                                                ? "Send All Pending Invites"
                                                : "Re-send All Pending Invites"
                                        }
                                        onClick={async ({ resetButton }) => {

                                            onConfirmBulkSend({
                                                Class: teacherLicense.Class,
                                                Id_Administrator_Teacher_License: teacherLicense.Id,
                                            })

                                            resetButton();
                                        }}
                                    />
                                )}

                                <Button
                                    bg={"#136AFF"}
                                    leftIcon={
                                        <BsPlusCircleFill
                                            color={"#FFFFFF"}
                                            size="14px"
                                        />
                                    }
                                    color={"#FFFFFF"}
                                    borderRadius={"41px"}
                                    height={"22px"}
                                    fontSize={"12px"}
                                    sx={{
                                        "& path": {
                                            stroke: "#FFFFFF",
                                        },
                                    }}
                                    onClick={onAddStudentOpenDialog({
                                        Grade: teacherLicense.Grade,
                                        Class: teacherLicense.Class,
                                        Id_Administrator_Teacher_License:
                                            teacherLicense.Id,
                                    })}
                                >
                                    Add student
                                </Button>
                            </HStack>
                        </HStack>
                    </VStack>
                    <TableContainer mt={"18px"}>
                        <Table
                            size="sm"
                            variant="simple"
                            bg={"#FFFFFF"}
                            borderRadius={"16px"}
                            sx={{
                                "& th": {
                                    color: "#5F6368",
                                    fontSize: "10px",
                                    textTransform: "capitalize",
                                    //wrap the text
                                },
                                "& td": {
                                    fontSize: "12px",
                                    whiteSpace: "nowrap",
                                },
                            }}
                        >
                            <Thead>
                                <Tr>
                                    {/* <Th>Grade</Th> */}
                                    <Th>Student Name</Th>
                                    <Th>Student Last Name</Th>
                                    <Th>Student Id</Th>
                                    {isAdminToolsEnabled() && <Th></Th>}
                                    <Th>Parent Name</Th>
                                    {isAdminToolsEnabled() && <Th></Th>}
                                    {/* <Th>Parent Approval</Th> */}
                                    <Th>
                                        Questionnaire <br /> Status
                                    </Th>
                                    <Th>
                                        Report <br /> Status
                                    </Th>
                                    <Th>
                                        Registration <br /> Status
                                    </Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {(licenses.data?.studentLicenses || []).map(
                                    (license) => {
                                        if (
                                            license.Id_Administrator_Teacher_License !==
                                            teacherLicense.Id
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <Tr key={license.Id}>
                                                {/* <Td>
                                                    {
                                                        license
                                                            .Administrator_Teacher_License
                                                            .Grade
                                                    }
                                                </Td> */}
                                                <Td>
                                                    {/* {(license.Id_Student && (
                                                        <>
                                                            <Icon
                                                                color={
                                                                    "#1B998B"
                                                                }
                                                                as={
                                                                    BsFillCheckCircleFill
                                                                }
                                                            />{" "}
                                                        </>
                                                    )) ||
                                                        (license.RegistrationType ===
                                                            "STUDENT" && (
                                                            <>
                                                                <Box
                                                                    display={
                                                                        "inline-block"
                                                                    }
                                                                    width="10px"
                                                                    height="10px"
                                                                    borderRadius="full"
                                                                    backgroundColor="orange.500"
                                                                />{" "}
                                                            </>
                                                    ))} */}
                                                    {license.StudentFirstName}{" "}
                                                </Td>
                                                <Td>
                                                    {license.StudentLastName}
                                                </Td>
                                                <Td>{license.StudentId}</Td>
                                                {isAdminToolsEnabled() && (
                                                    <Td>
                                                        {license.Id_Student && (
                                                            <Button
                                                                size="xs"
                                                                backgroundColor={
                                                                    "red"
                                                                }
                                                                onClick={() =>
                                                                    onImpersonate(
                                                                        license.Id_Student,
                                                                    )
                                                                }
                                                            >
                                                                Impersonate
                                                            </Button>
                                                        )}
                                                    </Td>
                                                )}
                                                <Td>
                                                    {(license.Id_Parent && (
                                                        <>
                                                            <Icon
                                                                color={
                                                                    "#1B998B"
                                                                }
                                                                as={
                                                                    BsFillCheckCircleFill
                                                                }
                                                            />{" "}
                                                        </>
                                                    )) ||
                                                        (license.RegistrationType ===
                                                            "PARENT" && (
                                                            <>
                                                                <Box
                                                                    display={
                                                                        "inline-block"
                                                                    }
                                                                    width="10px"
                                                                    height="10px"
                                                                    borderRadius="full"
                                                                    backgroundColor="orange.500"
                                                                />{" "}
                                                            </>
                                                        ))}
                                                    {license.ParentFirstName}{" "}
                                                    {license.ParentLastName}
                                                </Td>
                                                {isAdminToolsEnabled() && (
                                                    <Td>
                                                        {license.Id_Parent && (
                                                            <Button
                                                                size="xs"
                                                                backgroundColor={
                                                                    "red"
                                                                }
                                                                onClick={() =>
                                                                    onImpersonate(
                                                                        license.Id_Parent,
                                                                    )
                                                                }
                                                            >
                                                                Impersonate
                                                            </Button>
                                                        )}
                                                    </Td>
                                                )}
                                                {/*<Td>*/}
                                                {/*    {license.RegistrationType ===*/}
                                                {/*        "PARENT" && <>Share</>}*/}
                                                {/*    {license.RegistrationType ===*/}
                                                {/*        "STUDENT" && (*/}
                                                {/*        <Box color="red">*/}
                                                {/*            Do not share*/}
                                                {/*        </Box>*/}
                                                {/*    )}*/}
                                                {/*</Td>*/}
                                                <Td>
                                                    <HStack spacing={"10px"}>
                                                        <Box
                                                            w={"8px"}
                                                            h={"8px"}
                                                            bg={
                                                                [
                                                                    "STARTED",
                                                                    "PROGRESS_50",
                                                                    "COMPLETE",
                                                                ].includes(
                                                                    license
                                                                        .Assessment.length && license
                                                                        .Assessment[0]
                                                                        .Status,
                                                                )
                                                                    ? "#136AFF"
                                                                    : "#E1ECFF"
                                                            }
                                                            borderRadius={"50%"}
                                                        />
                                                        <Box
                                                            w={"8px"}
                                                            h={"8px"}
                                                            bg={
                                                                [
                                                                    "PROGRESS_50",
                                                                    "COMPLETE",
                                                                ].includes(
                                                                    license
                                                                        .Assessment.length && license
                                                                        .Assessment[0]
                                                                        .Status,
                                                                )
                                                                    ? "#136AFF"
                                                                    : "#E1ECFF"
                                                            }
                                                            borderRadius={"50%"}
                                                        />
                                                        <Box
                                                            w={"8px"}
                                                            h={"8px"}
                                                            bg={
                                                                [
                                                                    "COMPLETE",
                                                                ].includes(
                                                                    license
                                                                        .Assessment.length && license
                                                                        .Assessment[0]
                                                                        .Status,
                                                                )
                                                                    ? "#136AFF"
                                                                    : "#E1ECFF"
                                                            }
                                                            borderRadius={"50%"}
                                                        />

                                                        {license.registrationStatus ===
                                                            "REGISTERED" &&
                                                            (!license
                                                                .Assessment.length || license
                                                                .Assessment[0]
                                                                .Status !==
                                                                "COMPLETE") && (
                                                                <Tooltip
                                                                    label="Resend"
                                                                    aria-label="Resend"
                                                                    bg={
                                                                        "#F1F3F4"
                                                                    }
                                                                    borderRadius={
                                                                        "3px"
                                                                    }
                                                                    fontSize={
                                                                        "10px"
                                                                    }
                                                                    color={
                                                                        "black"
                                                                    }
                                                                    placement={
                                                                        "top-end"
                                                                    }
                                                                >
                                                                    <span>
                                                                        { license.Assessment.length && (
                                                                            <ProgressButton
                                                                                getTag={({
                                                                                    onClick,
                                                                                }) => (
                                                                                    <Image
                                                                                        cursor={
                                                                                            "pointer"
                                                                                        }
                                                                                        src={
                                                                                            resend
                                                                                        }
                                                                                        alt="Resend"
                                                                                        ml={
                                                                                            "5px"
                                                                                        }
                                                                                        width={
                                                                                            "14px"
                                                                                        }
                                                                                        height={
                                                                                            "14px"
                                                                                        }
                                                                                        onClick={
                                                                                            onClick
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                onClick={async ({
                                                                                    resetButton,
                                                                                }) => {
                                                                                    await onResendAssessmentInvite(
                                                                                        license
                                                                                            .Assessment[0]
                                                                                            .Id,
                                                                                    );
                                                                                    resetButton();
                                                                                }}
                                                                            />
                                                                        ) || ''}
                                                                    </span>
                                                                </Tooltip>
                                                            )}
                                                    </HStack>
                                                </Td>
                                                <Td>
                                                    {license.Assessment.length && license.Assessment[0]
                                                        .Status ===
                                                        "COMPLETE" && (
                                                        <>
                                                            {(license.RegistrationType ===
                                                                "PARENT" &&
                                                                !license.ShareWithTeacher && (
                                                                    <Box color="red">
                                                                        Not
                                                                        Shared
                                                                        by
                                                                        Parent
                                                                    </Box>
                                                                )) || (
                                                                <Button
                                                                    bg={
                                                                        "#136AFF"
                                                                    }
                                                                    color={
                                                                        "#FFFFFF"
                                                                    }
                                                                    borderRadius={
                                                                        "41px"
                                                                    }
                                                                    minHeight={
                                                                        "23px"
                                                                    }
                                                                    maxHeight={
                                                                        "23px"
                                                                    }
                                                                    fontSize={
                                                                        "10px"
                                                                    }
                                                                    width={
                                                                        "48px"
                                                                    }
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/teacher/reports/${teacherLicense.Id}/${license.Assessment[0].Id}`,
                                                                        )
                                                                    }
                                                                >
                                                                    VIEW
                                                                </Button>
                                                            )}
                                                        </>
                                                    ) || ''}
                                                </Td>
                                                <Td>
                                                    {license.registrationStatus ===
                                                        "REGISTERED" && (
                                                        <Icon
                                                            color={"#1B998B"}
                                                            as={
                                                                BsFillCheckCircleFill
                                                            }
                                                        />
                                                    )}
                                                    {license.registrationStatus !==
                                                        "REGISTERED" && (
                                                        <>
                                                            {(license.allowRegistrationInvite && (
                                                                <ProgressButton
                                                                    style={
                                                                        license.registrationStatus ===
                                                                        "ADDED"
                                                                            ? "primary"
                                                                            : "secondary"
                                                                    }
                                                                    label={
                                                                        license.registrationStatus ===
                                                                        "ADDED"
                                                                            ? "Send"
                                                                            : "Re-send"
                                                                    }
                                                                    onClick={async ({
                                                                        resetButton,
                                                                    }) => {
                                                                        await onSendInvite(
                                                                            license.Id,
                                                                        );
                                                                        resetButton();
                                                                    }}
                                                                />
                                                            )) || (
                                                                <Box
                                                                    display={
                                                                        "inline-block"
                                                                    }
                                                                    width="10px"
                                                                    height="10px"
                                                                    borderRadius="full"
                                                                    backgroundColor="orange.500"
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                </Td>
                                                <Td>
                                                    <Flex
                                                        placeItems={"center"}
                                                        gap={3}
                                                    >
                                                        { license.allowStudentDetailsEdit !== false && (
                                                            <Tooltip
                                                                label="Edit"
                                                                aria-label="Edit"
                                                                bg={"#F1F3F4"}
                                                                borderRadius={"3px"}
                                                                fontSize={"10px"}
                                                                color={"black"}
                                                                placement={
                                                                    "top-end"
                                                                }
                                                            >
                                                                <span>
                                                                    <Icon
                                                                        ml={"5px"}
                                                                        width={
                                                                            "10px"
                                                                        }
                                                                        height={
                                                                            "10px"
                                                                        }
                                                                        size={
                                                                            "10px"
                                                                        }
                                                                        stroke={
                                                                            "#0051DB"
                                                                        }
                                                                        borderColor={
                                                                            "#0051DB"
                                                                        }
                                                                        as={
                                                                            HiOutlinePencil
                                                                        }
                                                                        cursor={
                                                                            "pointer"
                                                                        }
                                                                        onClick={() => {
                                                                            setEditLicense(
                                                                                license,
                                                                            );
                                                                            onOpenEditModal();
                                                                        }}
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                        )}

                                                        { false && (
                                                            <Tooltip
                                                                label="Delete"
                                                                aria-label="Delete"
                                                                bg={"#F1F3F4"}
                                                                borderRadius={"3px"}
                                                                fontSize={"10px"}
                                                                color={"black"}
                                                                placement={
                                                                    "top-end"
                                                                }
                                                            >
                                                                <span>
                                                                    <Icon
                                                                        ml={"5px"}
                                                                        width={
                                                                            "10px"
                                                                        }
                                                                        height={
                                                                            "10px"
                                                                        }
                                                                        size={
                                                                            "10px"
                                                                        }
                                                                        stroke={
                                                                            "#0051DB"
                                                                        }
                                                                        borderColor={
                                                                            "#0051DB"
                                                                        }
                                                                        as={
                                                                            HiOutlineTrash
                                                                        }
                                                                        cursor={
                                                                            "pointer"
                                                                        }
                                                                        onClick={() => {
                                                                            setEditLicense(
                                                                                license,
                                                                            );
                                                                            onOpenDeleteModal();
                                                                        }}
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                        )}
                                                    </Flex>
                                                </Td>
                                            </Tr>
                                        );
                                    },
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </VStack>
            ))}
        </VStack>
    );
};
