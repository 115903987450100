import React, { FunctionComponent, useState } from "react";
import {
    Box,
    Link,
    Button,
    Flex,
    HStack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    Tooltip,
    Icon,
    useDisclosure,
} from "@chakra-ui/react";
import { Searchbar } from "../../components/common/Searchbar";
import { RouteComponentProps, Link as ReachLink } from "@reach/router";
import { TeacherModal } from "../../components/admin/TeacherModal";
import { useApp, isAdminToolsEnabled } from "../../../lib/app/App";
import { ProgressButton } from "../../components/common/ProgressButton";

import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { RellieSelect } from "../../components/common/RellieSelect";
import { SchoolModal } from "../../components/popup/SchoolModal";
import { isNil } from "lodash";
import { BsPlusCircleFill } from "react-icons/bs";
import { DeletePopup } from "../../components/common/DeletePopup";

const USER_OPTIONS = [
    { label: "Teacher", value: "TEACHER" },
    // { label: "School", value: "SCHOOL" },
    // { label: "School Board", value: "SCHOOL_BOARD" },
    // { label: "Government", value: "GOVERNMENT" },
];

export const AdminLicensesPage: FunctionComponent<RouteComponentProps> = () => {
    const { api, useData, webappOrigin, formatRequestUrl, onImpersonate } =
        useApp();

    const [selectedTypeOfUser, setSelectedTypeOfUser] = useState(
        USER_OPTIONS[0],
    );
    const {
        isOpen: isSchoolModalOpen,
        onOpen: onOpenSchoolModal,
        onClose: onCloseSchoolModal,
    } = useDisclosure();
    const {
        isOpen: isTeacherModalOpen,
        onOpen: onOpenTeacherModal,
        onClose: onCloseTeacherModal,
    } = useDisclosure();

    const onImport = (data) => {
        console.log(data);
    };
    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const {
        isOpen: isOpenEditModal,
        onOpen: onOpenEditModal,
        onClose: onCloseEditModal,
    } = useDisclosure();

    // const queryClient = useQueryClient();
    // const {
    //     data: clientsData,
    //     isLoading,
    //     isError,
    // } = useQuery<School[], Error, School[]>(["clients"], fetchSchools, {
    //     staleTime: Infinity,
    // });
    // const updateMutation = useMutation(updateSchool, {
    //     onSuccess: (newClients) => {
    //         // Update the data in the cache
    //         queryClient.setQueryData(["clients"], newClients);
    //     },
    // });
    // const deleteMutation = useMutation(deleteSchool, {
    //     onSuccess: (newSchools) => {
    //         // Update the data in the cache
    //         queryClient.setQueryData(["clients"], newSchools);
    //     },
    // });

    const clients = useData("Admin.TeacherLicenses");

    // let showBulkSend = null;
    // if (clients.data) {
    //     for (const client of clients.data) {
    //         if (client.registrationStatus === "ADDED") {
    //             showBulkSend = "SEND_ALL";
    //             break;
    //         }
    //         if (!showBulkSend && client.registrationStatus === "INVITED") {
    //             showBulkSend = "RESEND_ALL";
    //         }
    //     }
    // }

    async function onAddUser(type, data) {
        let response = null;

        if (type === "TEACHER") {
            response = await api.Admin.TeacherLicenses.Add.mutate({
                ...data,
                webappOrigin,
            });
        } else {
            alert(`type '${type}' not supported!`);
        }

        if (response.error) {
            return response;
        }

        clients.refetch();

        return true;
    }

    async function onUpdateUser(Id_Administrator_Teacher_License, data) {
        // console.log("onUpdateUser:", data);

        await api.Admin.TeacherLicenses.Update.mutate({
            Id_Administrator_Teacher_License,
            fields: data,
        });

        clients.refetch();

        return true;
    }

    async function onSendInvite(id: string) {
        if (selectedTypeOfUser.value === "TEACHER") {
            const email = clients.data.find((client) => client.id === id).email;

            const isPreview = window.localStorage.getItem(
                "re-unlock-preview-token",
            );
            if (isPreview) {
                window.localStorage.setItem(
                    "re-preview-invite-last-email",
                    email,
                );
            }

            await api.Admin.TeacherLicenses.SendInvitation.mutate({
                id,
                webappOrigin,
                registrationBaseUrl: formatRequestUrl(
                    "api",
                    "/api/user/register/from-email",
                ),
            });

            clients.refetch();
        } else {
            alert(`type '${selectedTypeOfUser.value}' not supported!`);
        }
    }

    // async function onSendAllInvites(registrationStatus) {
    //     if (selectedTypeOfUser.value === "TEACHER") {
    //         await api.Admin.TeacherLicenses.SendAllInvitations.mutate({
    //             registrationStatus,
    //             webappOrigin,
    //             registrationBaseUrl: formatRequestUrl(
    //                 "api",
    //                 "/api/user/register/from-email",
    //             ),
    //         });

    //         clients.refetch();
    //     } else {
    //         alert(`type '${selectedTypeOfUser.value}' not supported!`);
    //     }
    // }

    const handleDeleteClick = (client) => {
        setSelectedClient(client);
        setDeleteOpen(true);
    };

    const handleClose = () => {
        setDeleteOpen(false);
    };

    if (clients.isLoading || !clients.data || !clients.data.length) {
        return <div>Loading...</div>;
    }

    // if (isError) {
    //     return <div>Error: Unable to fetch clients data</div>;
    // }

    const renderAddModal = () => {
        if (selectedTypeOfUser.value === "TEACHER") {
            return (
                <TeacherModal
                    isOpen={isTeacherModalOpen}
                    onClose={() => {
                        onCloseTeacherModal();
                    }}
                    onSave={(data) => {
                        return onAddUser("TEACHER", data);
                    }}
                />
            );
        }
        if (
            selectedTypeOfUser.value === "SCHOOL" ||
            selectedTypeOfUser.value === "SCHOOL_BOARD" ||
            selectedTypeOfUser.value === "GOVERNMENT"
        ) {
            return (
                <SchoolModal
                    isAdmin={true}
                    onClose={() => {
                        onCloseSchoolModal();
                    }}
                    isOpen={isSchoolModalOpen}
                    handleAddSchool={() => {
                        alert('Not Yet Supported!')
                        //TODO to implement
                    }}
                    type={selectedTypeOfUser.value}
                />
            );
        }
    };

    const renderEditModal = () => {
        if (selectedClient?.type === "TEACHER" && isOpenEditModal) {
            return (
                <TeacherModal
                    isOpen={isOpenEditModal}
                    onClose={() => {
                        onCloseEditModal();
                    }}
                    defaultValues={selectedClient}
                    onSave={(data) => {
                        if (isNil(selectedClient)) {
                            throw new Error("Cannot save! No client selected.");
                        }
                        return onUpdateUser(selectedClient.id, data);
                    }}
                />
            );
        }
        //TODO this may change if the types from the backend are different
        if (
            selectedClient?.type === "SCHOOL" ||
            selectedClient?.type === "SCHOOL_BOARD" ||
            selectedClient?.type === "GOVERNMENT"
        ) {
            return (
                <SchoolModal
                    type={selectedClient?.type}
                    isAdmin={true}
                    onClose={() => {
                        onCloseSchoolModal();
                    }}
                    isOpen={isSchoolModalOpen}
                    defaultValues={selectedClient}
                    handleEditSchool={() => {
                        alert("Not Yet Supported!");
                        //TODO to implement
                    }}
                />
            );
        }
    };

    return (
        <VStack alignItems={"flex-start"} spacing={"19px"}>
            {renderEditModal()}
            <Searchbar />
            <Box alignSelf={"start"} color={"#032E59"} fontWeight={"700"}>
                <Text fontSize={"26px"}> Internal Rellie Administration</Text>

                <Text fontSize={"16px"}>License Management</Text>
            </Box>

            <Link
                to={"/admin/qa/reports-exports/invited_colleagues_no_classes"}
                as={ReachLink}
                getProps={({ }) => {
                    return {
                        style: {
                            color: "blue",
                            fontSize: "16px",
                        },
                    };
                }}
            >
                Invited colleagues with no classes report
            </Link>

            <VStack
                m={0}
                bg={"rgba(19, 106, 255, 0.03)"}
                borderRadius={"20px"}
                p={"17px"}
                w={"100%"}
                spacing={"18px"}
            >
                <HStack w={"100%"}>
                    <RellieSelect
                        value={selectedTypeOfUser.value}
                        placeholder={"SELECT ASSESSMENT"}
                        onChangeHandler={(value) =>
                            setSelectedTypeOfUser(
                                USER_OPTIONS.find(
                                    (option) => option.value === value,
                                ),
                            )
                        }
                        options={USER_OPTIONS}
                    />
                    <Text
                        fontSize={"16px"}
                        color={"#032E59"}
                        alignSelf={"start"}
                        lineHeight={"20px"}
                        fontWeight={"700"}
                    >
                        Licenses
                    </Text>
                </HStack>
                <HStack w={"100%"} justifyContent={"space-between"}>
                    <HStack spacing={"55px"}>
                        {/* <Text fontSize={"14px"}>Available: 50</Text>
                        <Text fontSize={"14px"}>Total: 100</Text> */}
                    </HStack>
                    <HStack>
                        <Button
                            bg={"#136AFF"}
                            leftIcon={
                                <BsPlusCircleFill
                                    color={"#FFFFFF"}
                                    size="14px"
                                />
                            }
                            color={"#FFFFFF"}
                            borderRadius={"41px"}
                            height={"22px"}
                            fontSize={"12px"}
                            sx={{
                                "& path": {
                                    stroke: "#FFFFFF",
                                },
                            }}
                            onClick={() => {
                                if (selectedTypeOfUser.value === "TEACHER") {
                                    onOpenTeacherModal();
                                } else if (
                                    selectedTypeOfUser.value === "SCHOOL" ||
                                    selectedTypeOfUser.value ===
                                        "SCHOOL_BOARD" ||
                                    selectedTypeOfUser.value === "GOVERNMENT"
                                ) {
                                    onOpenSchoolModal();
                                }
                            }}
                        >
                            Add License
                        </Button>
                        {/* <Import onImport={onImport} /> */}
                        {renderAddModal()}
                    </HStack>
                </HStack>
                <TableContainer mt={"18px"}>
                    <Table
                        variant="simple"
                        bg={"#FFFFFF"}
                        borderRadius={"16px"}
                        sx={{
                            "& th": {
                                color: "#5F6368",
                                fontSize: "10px",
                                textTransform: "capitalize",
                            },
                            "& td": {
                                fontSize: "12px",
                            },
                        }}
                    >
                        <Thead>
                            <Tr>
                                <Th>Client Type</Th>
                                <Th>Rellie Quote #</Th>
                                <Th>Contact</Th>
                                <Th>Contact Email</Th>
                                {isAdminToolsEnabled() && <Th></Th>}
                                <Th>Grade</Th>
                                <Th>Class</Th>
                                <Th>Licenses Used</Th>
                                <Th>Registration Status</Th>
                                <Th>Created By</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {clients.data.map((client) => (
                                <Tr key={client.id}>
                                    <Td>
                                        {client.type === "TEACHER" && `Teacher`}
                                    </Td>
                                    <Td>
                                        {client.quoteNumber}
                                    </Td>
                                    <Td>{client.firstName}</Td>
                                    <Td>{client.email}</Td>
                                    {isAdminToolsEnabled() && (
                                        <Td>
                                            {client.Id_User && (
                                                <Button
                                                    size="xs"
                                                    backgroundColor={"red"}
                                                    onClick={() =>
                                                        onImpersonate(
                                                            client.Id_User,
                                                        )
                                                    }
                                                >
                                                    Impersonate
                                                </Button>
                                            )}
                                        </Td>
                                    )}
                                    <Td>{client.grade}</Td>
                                    <Td>{client.class}</Td>
                                    <Td>
                                        {client.licensesUsedCount} /{" "}
                                        {client.licensesMaxCount}
                                    </Td>
                                    <Td>
                                        <Flex placeItems={"center"} gap={3}>
                                            {client.registrationStatus ==
                                                "REGISTERED" && (
                                                <>
                                                    <Box
                                                        display={"inline-block"}
                                                        width="10px"
                                                        height="10px"
                                                        borderRadius="full"
                                                        backgroundColor="green.500"
                                                    />
                                                    Registered
                                                </>
                                            )}
                                            {client.registrationStatus !==
                                                "REGISTERED" && (
                                                <>
                                                    {(client.allowRegistrationInvite && (
                                                        <ProgressButton
                                                            style={
                                                                client.registrationStatus ===
                                                                "ADDED"
                                                                    ? "primary"
                                                                    : "secondary"
                                                            }
                                                            label={
                                                                client.registrationStatus ===
                                                                "ADDED"
                                                                    ? "Send"
                                                                    : "Re-send"
                                                            }
                                                            onClick={async ({
                                                                resetButton,
                                                            }) => {
                                                                await onSendInvite(
                                                                    // client.type,
                                                                    client.id,
                                                                );
                                                                resetButton();
                                                            }}
                                                        />
                                                    )) || (
                                                        <Box
                                                            display={
                                                                "inline-block"
                                                            }
                                                            width="10px"
                                                            height="10px"
                                                            borderRadius="full"
                                                            backgroundColor="orange.500"
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </Flex>
                                    </Td>
                                    <Td>{client.createdByName}</Td>

                                    <Td>
                                        <Flex placeItems={"center"} gap={3}>
                                            <Tooltip
                                                label="Edit"
                                                aria-label="Edit"
                                                bg={"#F1F3F4"}
                                                borderRadius={"3px"}
                                                fontSize={"10px"}
                                                color={"black"}
                                                placement={"top-end"}
                                            >
                                                <span>
                                                    <Icon
                                                        ml={"5px"}
                                                        width={"10px"}
                                                        height={"10px"}
                                                        size={"10px"}
                                                        stroke={"#0051DB"}
                                                        borderColor={"#0051DB"}
                                                        as={HiOutlinePencil}
                                                        cursor={"pointer"}
                                                        onClick={() => {
                                                            onOpenEditModal();
                                                            setSelectedClient(
                                                                client,
                                                            );
                                                        }}
                                                    />
                                                </span>
                                            </Tooltip>

                                            {/* <Tooltip
                                                label="Delete"
                                                aria-label="Delete"
                                                bg={"#F1F3F4"}
                                                borderRadius={"3px"}
                                                fontSize={"10px"}
                                                color={"black"}
                                                placement={"top-end"}
                                            >
                                                <span>
                                                    <Icon
                                                        ml={"5px"}
                                                        width={"10px"}
                                                        height={"10px"}
                                                        size={"10px"}
                                                        stroke={"#0051DB"}
                                                        borderColor={"#0051DB"}
                                                        as={HiOutlineTrash}
                                                        cursor={"pointer"}
                                                        onClick={() => {
                                                            handleDeleteClick(
                                                                client,
                                                            );
                                                        }}
                                                    />
                                                </span>
                                            </Tooltip> */}
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>

            {selectedClient && (
                <DeletePopup
                    isOpen={isDeleteOpen}
                    onClose={handleClose}
                    onDelete={() => {
                        //TODO implement delete
                        handleClose();
                    }}
                    confirmationText={`Are you sure you want to delete license ${selectedClient.firstName}?`}
                />
            )}
        </VStack>
    );
};
