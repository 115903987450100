import React, { FunctionComponent, useState, useEffect } from "react";
import {
    Text,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    chakra,
    Heading,
    Icon,
    VStack,
    HStack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { InputForm } from "../common/InputForm";
import { useForm } from "react-hook-form";
import { PiShare } from "react-icons/pi";
import { RiParentLine } from "react-icons/ri";
import { BsFillCheckCircleFill, BsPlusCircleFill } from "react-icons/bs";
import { ProgressButton } from "../common/ProgressButton";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useApp } from "../../../lib/app/App";

const schema = z.object({
    FirstName: z
        .string()
        .min(1, "First name is required")
        .max(64, "First name is too long"),
    LastName: z
        .string()
        .min(1, "Last name is required")
        .max(64, "Last name is too long"),
});

export const CompleteProfileModal: FunctionComponent = ({ }) => {

    const { api, useData } = useApp();

    const userData = useData("User");

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
        reset,
    } = useForm({
        resolver: zodResolver(schema),
        shouldUnregister: false,
    });
    const { isOpen, onOpen, onClose } = useDisclosure();


    useEffect(() => {
        if (!userData.data?.user.FirstName || !userData.data?.user.LastName) {
            onOpen()
        } else {
            console.log('CLOSE')
            onClose()
        }
    }, [userData.data?.user.FirstName, userData.data?.user.LastName])


    const onSave = async (data) => {
        await api.User.CompleteProfile.mutate({
            FirstName: data.FirstName,
            LastName: data.LastName
        })
        userData.refetch()
        reset()
    };

    return (
        <>
            <Modal isOpen={isOpen} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent p={5} maxW="50%" alignItems={"center"}>
                    <ModalHeader mt="14px" textAlign={"center"} width="100%" padding="0px" marginBottom="15px">
                        <Icon
                            fontSize={"46px"}
                            as={RiParentLine}
                            color={"#136AFF"}
                        />
                        <Heading
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="26px"
                            lineHeight="20px"
                            color="#032E59"
                        >
                            Complete Profile
                        </Heading>
                    </ModalHeader>
                    <chakra.form>
                        <ModalBody
                            color={"black"}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            textAlign={"center"}
                            gap={"22px"}
                        >

                            <Text>
                                Use the form below to complete your Rellie profile.
                            </Text>

                            <VStack align={"top"}>
                                <InputForm
                                    register={register}
                                    type={"text"}
                                    name={"FirstName"}
                                    placeholder={"First Name"}
                                    height={"30px"}
                                    error={
                                        errors?.FirstName
                                            ?.message as
                                            | string
                                            | undefined
                                    }
                                    rest={{
                                        maxWidth: "340px",
                                        border: "1px solid #ACCBFF",
                                        borderColor: "#ACCBFF",
                                        borderRadius: "37px",
                                        bg: "rgba(172, 203, 255, 0.20)",
                                        mx: "0px",
                                        fontSize: "14px",
                                        textAlign: "left",
                                    }}
                                />
                                <InputForm
                                    register={register}
                                    type={"text"}
                                    name={"LastName"}
                                    placeholder={"LastName"}
                                    height={"30px"}
                                    error={
                                        errors?.LastName
                                            ?.message as
                                            | string
                                            | undefined
                                    }
                                    rest={{
                                        maxWidth: "340px",
                                        border: "1px solid #ACCBFF",
                                        borderColor: "#ACCBFF",
                                        borderRadius: "37px",
                                        bg: "rgba(172, 203, 255, 0.20)",
                                        mx: "0px",
                                        fontSize: "14px",
                                        textAlign: "left",
                                    }}
                                />
                            </VStack>
                        </ModalBody>
                        <ModalFooter justifyContent="center">
                            
                            <ProgressButton
                                getTag={({ onClick }) => (
                                    <Button
                                        w="100px"
                                        h="30px"
                                        bg="#136AFF"
                                        borderRadius="37px"
                                        color="#fff"
                                        fontSize="14px"
                                        fontWeight="400"
                                        lineHeight="16px"
                                        onClick={onClick}
                                    >
                                        Save Profile
                                    </Button>
                                )}
                                onClick={async ({ resetButton }) => {
                                    await handleSubmit(onSave)()
                                    resetButton();
                                }}
                            />
                        </ModalFooter>
                    </chakra.form>
                </ModalContent>
            </Modal>
        </>
    );
};
