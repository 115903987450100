import React, { FunctionComponent, useState } from "react";
import {
    Text,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    chakra,
    Heading,
    Icon,
    HStack,
    VStack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { InputForm } from "../common/InputForm";
import { useForm } from "react-hook-form";
import { PiShare } from "react-icons/pi";
import { RiParentLine } from "react-icons/ri";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { ProgressButton } from "../common/ProgressButton";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AdminFillFormButton } from "../../../lib/app/AdminFillFormButton";
import { useApp } from "../../../lib/app/App";

const schema = z.object({
    // ColleagueName: z.string().min(2, { message: "Please enter a name" }),
    ColleagueEmail: z.string().email(),
});

export const InviteColleagueModal: FunctionComponent = ({ }) => {

    const { api, useData, webappOrigin, formatRequestUrl } = useApp();

    const userData = useData("User");

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
        reset,
    } = useForm({
        resolver: zodResolver(schema),
        shouldUnregister: false,
    });
    const { isOpen, onOpen, onClose } = useDisclosure();

    function onAutofill() {
        const randomId = Math.floor(Math.random() * 9000 + 1000);

        const values = {
            ColleagueEmail:
                (userData.data.user.Email &&
                    userData.data.user.Email.replace(
                        /(\+[^@]+)?@/,
                        `+colleague-${randomId}@`,
                    )) ||
                "",
        }

        Object.keys(values).forEach((name) => {
            setValue(name as keyof typeof values, values[name]);
        });
    }
    
    const sharesData = useData("Teacher.Colleagues");

    const [error, setError] = useState()

    const onInvite = async (data) => {
        const result = await api.Teacher.Colleagues.Invite.mutate({
            // ColleagueName: data.ColleagueName,
            ColleagueEmail: data.ColleagueEmail,
            webappOrigin,
            registrationBaseUrl: formatRequestUrl(
                "api",
                "/api/user/register/from-email",
            ),
        })
        if (result.error) {
            if (['NOT_SAME_SCHOOL', 'COLLEAGE_ALREADY_REGISTERED', 'COLLEAGE_ALREADY_INVITED'].includes(result.error.code)) {
                setError(result.error.message)
            } else {
                console.error('result.error', result.error)
                throw new Error(`Unknown error!`)
            }
            setTimeout(() => {
                setError(undefined)
            }, 3000)
        }
        sharesData.refetch()
        reset()
    };

    const onResendInvite = async (Id) => {
        const result = await api.Teacher.Colleagues.sendInvite.mutate({
            Id,
            webappOrigin,
            registrationBaseUrl: formatRequestUrl(
                "api",
                "/api/user/register/from-email",
            ),
        })
        if (result.error) {
            if (['COLLEAGE_ALREADY_REGISTERED'].includes(result.error.code)) {
                setError(result.error.message)
            } else {
                console.error('result.error', result.error)
                throw new Error(`Unknown error!`)
            }
            setTimeout(() => {
                setError(undefined)
            }, 3000)
        }
        sharesData.refetch()        
    }

    return (
        <>
            <Button
                ml={"20px"}
                color={"white"}
                borderRadius={"60px"}
                h={"24px"}
                bg={"#136AFF"}
                paddingLeft={"10px"}
                paddingRight={"10px"}
                float={"right"}
                leftIcon={<PiShare />}
                fontSize={"12px"}
                onClick={() => {
                    reset();
                    onOpen();
                }}
            >
                Invite Colleague to Rellie
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent p={5} maxW="50%" alignItems={"center"}>
                    <ModalHeader mt="14px" textAlign={"center"} width="100%" padding="0px" marginBottom="15px">
                        <Icon
                            fontSize={"46px"}
                            as={RiParentLine}
                            color={"#136AFF"}
                        />
                        <Button
                            float={"right"}
                            h="30px"
                            bg="#fff"
                            border="1px solid #ACCBFF"
                            borderRadius="37px"
                            color="#032E59"
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="16px"
                            mr="10px"
                            onClick={onClose}
                        >
                            Close
                        </Button>
                        <Heading
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="26px"
                            lineHeight="20px"
                            color="#032E59"
                        >
                            Invite a Colleague
                        </Heading>
                    </ModalHeader>
                    <chakra.form>
                        <ModalBody
                            color={"black"}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            textAlign={"center"}
                            gap={"22px"}
                        >
                            { sharesData.data?.length > 0 && (
                                <Table
                                    variant="simple"
                                    bg={"#FFFFFF"}
                                    borderRadius={"16px"}
                                    sx={{
                                        "& th": {
                                            color: "#5F6368",
                                            fontSize: "10px",
                                            padding: "8px",
                                            textAlign: "left",
                                            // wordBreak: "normal",
                                            whiteSpace: "normal",
                                            maxWidth: "130px",
                                        },
                                        "tr th:nth-child(3)": {
                                            textAlign: "center",
                                        },
                                        td: {
                                            fontSize: "14px",
                                            padding: "8px",
                                            textAlign: "left",
                                            color: "#5F6368",
                                            minWidth: "130px",
                                            whiteSpace: "nowrap"
                                        },
                                        "tr td:nth-child(3)": {
                                            textAlign: "center",
                                        },
                                    }}
                                >
                                    <Thead>
                                        <Tr>
                                            {/* <Th>Name</Th> */}
                                            <Th>Email</Th>
                                            <Th>Registered</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {sharesData.isLoading && (
                                            <Tr>
                                                <Td colspan="2">Loading ...</Td>
                                            </Tr>
                                        ) || (
                                            sharesData.data.map((record, i) => (
                                                <Tr key={i}>
                                                    {/* <Td>{record.ColleagueName}</Td> */}
                                                    <Td>{record.ColleagueEmail}</Td>
                                                    <Td>{record.status === "invited" && (
                                                        <ProgressButton
                                                            style={"secondary"}
                                                            label={"Re-send"}
                                                            onClick={async ({
                                                                resetButton,
                                                            }) => {
                                                                await onResendInvite(record.Id)
                                                                resetButton()
                                                            }}
                                                        />
                                                    ) || record.status === "registered" && (
                                                        <Icon
                                                            color={"#1B998B"}
                                                            as={
                                                                BsFillCheckCircleFill
                                                            }
                                                        />
                                                    )}</Td>
                                                </Tr>
                                            ))
                                        )}
                                    </Tbody>
                                </Table>
                            ) }

                            <Text>
                                Provide an Email to invite a Colleague to the Rellie Platform.
                                They will be able to build classes of their own and view existing student reports for your school.
                            </Text>

                            <HStack align={"top"}>
                                {/* <InputForm
                                    register={register}
                                    type={"name"}
                                    name={"ColleagueName"}
                                    placeholder={"Name"}
                                    height={"30px"}
                                    error={
                                        errors?.ColleagueName
                                            ?.message as
                                            | string
                                            | undefined
                                    }
                                    rest={{
                                        maxWidth: "340px",
                                        border: "1px solid #ACCBFF",
                                        borderColor: "#ACCBFF",
                                        borderRadius: "37px",
                                        bg: "rgba(172, 203, 255, 0.20)",
                                        mx: "0px",
                                        fontSize: "14px",
                                        textAlign: "left",
                                    }}
                                /> */}
                                <AdminFillFormButton onClick={onAutofill} />
                                <InputForm
                                    register={register}
                                    type={"name"}
                                    name={"ColleagueEmail"}
                                    placeholder={"Email"}
                                    height={"30px"}
                                    error={
                                        errors?.ColleagueEmail
                                            ?.message as
                                            | string
                                            | undefined
                                    }
                                    rest={{
                                        maxWidth: "340px",
                                        border: "1px solid #ACCBFF",
                                        borderColor: "#ACCBFF",
                                        borderRadius: "37px",
                                        bg: "rgba(172, 203, 255, 0.20)",
                                        mx: "0px",
                                        fontSize: "14px",
                                        textAlign: "left",
                                    }}
                                />
                            </HStack>
                        </ModalBody>
                        <ModalFooter justifyContent="center">
                            <VStack>
                                {error && (
                                    <Text fontWeight="bold" fontSize={"14px"} color="#FF0000">
                                        {error}
                                    </Text>
                                )}                           
                                <ProgressButton
                                    getTag={({ onClick }) => (
                                        <Button
                                            w="100px"
                                            h="30px"
                                            bg="#136AFF"
                                            borderRadius="37px"
                                            color="#fff"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="16px"
                                            onClick={onClick}
                                        >
                                            Invite
                                        </Button>
                                    )}
                                    onClick={async ({ resetButton }) => {
                                        await handleSubmit(onInvite)()
                                        resetButton();
                                    }}
                                />
                            </VStack>
                        </ModalFooter>
                    </chakra.form>
                </ModalContent>
            </Modal>
        </>
    );
};
