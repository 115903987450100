import React, { FunctionComponent, useState } from "react";
import {
    Text,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    chakra,
    Heading,
    Icon,
    HStack,
    VStack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { InputForm } from "../common/InputForm";
import { useForm } from "react-hook-form";
import { PiShare } from "react-icons/pi";
import { RiParentLine } from "react-icons/ri";
import { BsFillCheckCircleFill, BsPlusCircleFill } from "react-icons/bs";
import { ProgressButton } from "../common/ProgressButton";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useApp } from "../../../lib/app/App";

const schema = z.object({
    ClassName: z.string().min(2, { message: "Please enter a name" }),
    ClassGrade: z.preprocess(
        (v) => parseInt(z.string().parse(v), 10) || 0,
        z.number().min(3, "Grade (3 - 10) is required").max(10, "Grade (3 - 10) is required"),
    ),
});

export const AddClassModal: FunctionComponent = ({ onClassAdded }) => {

    const { api, useData } = useApp();

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
        reset,
    } = useForm({
        resolver: zodResolver(schema),
        shouldUnregister: false,
    });
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [error, setError] = useState()

    const onCreate = async (data) => {
        const result = await api.Teacher.Class.Create.mutate({
            ClassName: data.ClassName,
            ClassGrade: data.ClassGrade
        })
        if (result?.error) {
            setError(result.error.message)
            setTimeout(() => {
                setError(undefined)
            }, 3000)
            return
        }
        onClassAdded()
        onClose()
        reset()
    };

    return (
        <>
            <Button
                ml={"20px"}
                color={"white"}
                borderRadius={"60px"}
                h={"24px"}
                bg={"#136AFF"}
                paddingLeft={"10px"}
                paddingRight={"10px"}
                float={"right"}
                leftIcon={<BsPlusCircleFill
                    color={"#FFFFFF"}
                    size="14px"
                />}
                fontSize={"12px"}
                onClick={() => {
                    reset();
                    onOpen();
                }}
            >
                Create a new Class
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent p={5} maxW="50%" alignItems={"center"}>
                    <ModalHeader mt="14px" textAlign={"center"} width="100%" padding="0px" marginBottom="15px">
                        <Icon
                            fontSize={"46px"}
                            as={RiParentLine}
                            color={"#136AFF"}
                        />
                        <Button
                            float={"right"}
                            h="30px"
                            bg="#fff"
                            border="1px solid #ACCBFF"
                            borderRadius="37px"
                            color="#032E59"
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="16px"
                            mr="10px"
                            onClick={onClose}
                        >
                            Close
                        </Button>
                        <Heading
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="26px"
                            lineHeight="20px"
                            color="#032E59"
                        >
                            Create a new Class
                        </Heading>
                    </ModalHeader>
                    <chakra.form>
                        <ModalBody
                            color={"black"}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            textAlign={"center"}
                            gap={"22px"}
                        >

                            <Text>
                                Use the form below to create a new class.
                            </Text>

                            <HStack align={"top"}>
                                <InputForm
                                    register={register}
                                    type={"text"}
                                    name={"ClassName"}
                                    placeholder={"Class Name"}
                                    height={"30px"}
                                    error={
                                        errors?.ClassName
                                            ?.message as
                                            | string
                                            | undefined
                                    }
                                    rest={{
                                        maxWidth: "340px",
                                        border: "1px solid #ACCBFF",
                                        borderColor: "#ACCBFF",
                                        borderRadius: "37px",
                                        bg: "rgba(172, 203, 255, 0.20)",
                                        mx: "0px",
                                        fontSize: "14px",
                                        textAlign: "left",
                                    }}
                                />
                                <InputForm
                                    register={register}
                                    type={"number"}
                                    name={"ClassGrade"}
                                    placeholder={"Grade"}
                                    height={"30px"}
                                    error={
                                        errors?.ClassGrade
                                            ?.message as
                                            | string
                                            | undefined
                                    }
                                    rest={{
                                        maxWidth: "340px",
                                        border: "1px solid #ACCBFF",
                                        borderColor: "#ACCBFF",
                                        borderRadius: "37px",
                                        bg: "rgba(172, 203, 255, 0.20)",
                                        mx: "0px",
                                        fontSize: "14px",
                                        textAlign: "left",
                                    }}
                                />
                            </HStack>
                        </ModalBody>
                        <ModalFooter justifyContent="center">
                            <VStack>
                                {error && (
                                    <Text fontWeight="bold" fontSize={"14px"} color="#FF0000">
                                        {error}
                                    </Text>
                                )}                                
                                <ProgressButton
                                    getTag={({ onClick }) => (
                                        <Button
                                            w="100px"
                                            h="30px"
                                            bg="#136AFF"
                                            borderRadius="37px"
                                            color="#fff"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="16px"
                                            onClick={onClick}
                                        >
                                            Create Class
                                        </Button>
                                    )}
                                    onClick={async ({ resetButton }) => {
                                        await handleSubmit(onCreate)()
                                        resetButton();
                                    }}
                                />
                            </VStack>                                
                        </ModalFooter>
                    </chakra.form>
                </ModalContent>
            </Modal>
        </>
    );
};
